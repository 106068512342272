<template>
   <error404 v-if="error404"></error404>
   <div v-else>
      <loadScreenComponent :isLoading="isLoading"/>
      <nav style="background-color: #37bc9b" class="mb-5">
         <div style="height: 3.5rem" class="p-2">
            <img
               style="height: 2.5rem"
               :src="logo">
         </div>
      </nav>
      <div class="row gutters main-container w-100">
         <div v-for="iten in  data?.allotments" :key="iten.id" class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
            <figure class="user-card">
               <figcaption>
                  <div>
                     <img alt="Logo Loteamento" style="margin: auto;" class="profile mb-2" :src="iten.image"
                          v-if="iten.image">
                     <h5 :title="iten.name" class="mt-3">{{ iten.name.substring(0, 30) }}</h5>
                     {{ iten.cities.name }} - {{ iten.cities.states.name }}
                  </div>
                  <div class="btn btn-primary mt-3" @click="sendUrl(iten)">{{t('GROUP.ACESS_MAP')}}</div>
               </figcaption>
            </figure>
         </div>
         <div class="card w-100" v-if="data && !data?.allotments?.length">
            <div class="card-header"></div>
            <div class="card-body pt-0">
               <div class="text-center">
                  <h5>{{ t('GROUP.NONE_ALLOTMENT_FOUD') }}</h5>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import PublicArea from '../../services/publicArea';
import error404 from '../../components/layouts/error404';
import loadScreenComponent from '../../components/layouts/loadScreenComponent';
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import {mapState} from 'vuex';
export default {
   name: "indexPublicMapasComponent",
   data() {
      return{
         data: [],
         logo: null,
         error404: null,
      }
   },
   computed: {
      ...mapState({
         isLoading: (state) => state.isLoading,
      })
   },
   components: {
      error404,
      loadScreenComponent
   },
   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {t, toast}
   },
   mounted() {
      this.index();
   },
   methods: {
      index() {
         this.$store.commit('changeLoading', true);
         PublicArea.listAllotmentsDataMap(this.$route.params.groupid).then((resp) => {
            this.data = resp.data;
            this.logo = this.data.company.find(i => i)?.logo;
            this.$store.commit('changeLoading', false);
         }).catch((err) => {
            if (err.response.status === 404){
               this.error404 = true;
            }
            this.$store.commit('changeLoading', false);
         })
      },
      sendUrl(item) {
         window.open(item.url_site, 'blank')
      }
   }
}
</script>

<style>
.paddin {
   padding-left: 1rem !important;
   padding-top: 0.75rem !important;
   padding-bottom: 0.5rem !important;
}

body.authentication {
   display: block;
   align-items: flex-start;
   justify-content: left;
   background: #eff1f5;
}

.modal-row {
   max-height: 100%;
   overflow-y: auto;
}

.modal-row::-webkit-scrollbar {
   width: 7px;
   border-radius: 10px;
   background: transparent !important;
}

.modal-row::-webkit-scrollbar-thumb {
   background: lightgray;
   border-radius: 10px;
}

.secondary {
   border-radius: 3px;
   color: #ffffff !important;
   background-color: #383737;
}

.first {
   border-radius: 0px;
   background: transparent;
   margin: 0;
   list-style: none;
   display: flex;
   flex-wrap: wrap;
   padding: 1.5rem 0;
   font-size: 1.5rem;
   align-items: center;
}

.info-lot {
   border-radius: 7px;
   padding-bottom: 1px;
   padding-top: 6px;
}

.space-between {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   border-bottom-right-radius: calc(.3rem - 1px);
   border-bottom-left-radius: calc(.3rem - 1px);
}

.color-grey {
   color: #727272;
}

.font {
   font-size: 0.8rem;
}

.fab {
   position: fixed;
   bottom: 5px;
   right: 10px;
}

.fab button {
   cursor: pointer;
   width: 100%;
   height: 45px;
   border-radius: 20px;
   background-color: #383737;
   border: none;
   box-shadow: 0 1px 5px rgba(0, 0, 0, .4);
   font-size: 0.9rem;
   color: black;

   -webkit-transition: .2s ease-out;
   -moz-transition: .2s ease-out;
   transition: .2s ease-out;
}

.fab button.main {
   position: absolute;
   width: 60px;
   height: 60px;
   border-radius: 30px;
   background-color: #0e5e4e !important;
   right: 0;
   bottom: 0;
   z-index: 20;
}

.fab ul {
   position: absolute;
   bottom: 0;
   right: 0;
   padding: 0;
   padding-right: 5px;
   margin: 0;
   list-style: none;
   z-index: 10;

   -webkit-transition: .2s ease-out;
   -moz-transition: .2s ease-out;
   transition: .2s ease-out;
}

.fab ul li {
   display: flex;
   justify-content: flex-start;
   position: relative;
   margin-bottom: -10%;
   opacity: 0;

   -webkit-transition: .3s ease-out;
   -moz-transition: .3s ease-out;
   transition: .3s ease-out;
}

.fab ul li label {
   margin-right: 10px;
   white-space: nowrap;
   display: block;
   margin-top: 10px;
   padding: 5px 8px;
   background-color: white;
   box-shadow: 0 1px 3px rgba(0, 0, 0, .2);
   border-radius: 3px;
   height: 18px;
   font-size: 16px;
   pointer-events: none;
   opacity: 0;

   -webkit-transition: .2s ease-out;
   -moz-transition: .2s ease-out;
   transition: .2s ease-out;
}

.fab button.main:active,
.fab button.main:focus {
   outline: none;
   background-color: #000000;
   box-shadow: 0 3px 8px rgba(0, 0, 0, .5);
}

.fab button.main:active + ul,
.fab button.main:focus + ul {
   bottom: 70px;
}

.fab button.main:active + ul li,
.fab button.main:focus + ul li {
   margin-bottom: 8px;
   opacity: 1;
}

.fab button.main:active + ul li:hover label,
.fab button.main:focus + ul li:hover label {
   opacity: 1;
}
</style>